.txtinp::placeholder {
  color: #E5E4E5;
  font-size: 14px;
}
.txtinp::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #E5E4E5;
  font-size: 14px;
}
/* table {
  width: 100%;
  border-collapse: collapse;
  background-color: #FFFFFF;
}
td,
th {
  text-align: left;
  padding: 16px;
}
tr:nth-child(even) {
  background-color: rgba(229, 228, 229, 0.15);
}
.increaseCheckbox input {
  width: 26px;
  height: 26px;
}
 */


 .ReactModal__Overlay--after-open{
  background-color: rgba(51, 51, 51, 0.5) !important;
}


