@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: #333333;
  }
  * {
    box-sizing: border-box;
    transition: all 0.15s ease-out;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:focus,
  select:focus {
    box-shadow: none !important;
    outline-width: 0 !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
@layer components {
  .steps {
    @apply w-full max-w-xl mx-auto;
  }
  .steps .step {
    @apply min-w-12 lg:min-w-16;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    transition: box-shadow 200ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .sticky-header.active {
    box-shadow: hsla(0 0% 0% / 0.1) 0 0.5rem 1rem;
    transition-duration: 400ms;
  }

  .grid-container--fill {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .data-table-wrapper {
    @apply w-full overflow-x-auto max-h-[80vh];
  }
  .data-table-wrapper--flat {
    @apply border-none;
  }
  .data-table-wrapper--elevated {
    @apply border border-[#EFEFEF] rounded-lg;
  }

  table {
    @apply bg-white w-full z-0 text-left text-dark-primary;
  }

  table th {
    @apply bg-white text-sm px-2 py-4 font-medium capitalize;
  }
  table tr {
    @apply border-b border-[#EFEFEF] bg-white;
  }

  table.table--hover tbody tr {
    @apply cursor-pointer;
  }

  table th:first-of-type,
  table tr td:first-of-type {
    @apply sticky left-0;
  }

  table thead tr {
    @apply sticky top-0 z-10 
    shadow-[0_1px_0_0_#EFEFEF];
  }

  table td {
    @apply bg-white text-sm px-2 py-4 transition-all capitalize;
  }

  table.table--striped tbody tr:nth-child(odd) td {
    @apply bg-[#fcfbfc];
  }

  table.table--hover tbody tr:hover td {
    @apply bg-base-100;
  }

  .btn {
    @apply text-white capitalize font-normal;
  }

  .btn-icon {
    @apply p-2 flex bg-transparent rounded-full transition-all;
  }

  .card.is-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  @media screen and (max-width: 576px) {
    .btn {
      @apply px-2 min-h-[2.5rem];
    }
  }

  .input {
    @apply bg-white border-[#CBCBCB] h-fit min-h-[unset] text-sm;
  }
  .select {
    @apply bg-white border-[#CBCBCB] h-fit min-h-[unset] text-sm;
  }

  .btn[disabled] *,
  .btn-disabled *,
  .btn[disabled]:hover *,
  .btn-disabled:hover * {
    @apply opacity-[0.45];
  }
  .btn-outline.btn-primary:hover {
    @apply text-white;
  }
  .btn-primary:not(.btn-outline):not(:disabled) {
    @apply bg-[#FF4D00];
  }
  .btn-secondary {
    @apply bg-black;
  }
  .btn-rounded {
    @apply rounded-[100px];
  }

  .select {
    font-weight: inherit;
  }

  .pagination-button {
    @apply p-2 rounded-[5px] border-2 border-[#D7DDE5] bg-[transparent] text-[#2F3A36];
  }
  .pagination-button.active {
    @apply bg-[#FF4D00] text-white border-[#FF4D00];
  }

  .steps {
    @apply flex flex-row flex-wrap justify-center;
  }

  .steps .step:before {
    height: 0;
  }
  .steps .step:after {
    background-color: #f2f2f2;
    color: #ff4d00;
  }
  .steps .step-primary + .step-primary:before,
  .steps .step-primary:after {
    background-color: #ff4d00;
    color: #ffffff;
  }
  .search__group {
    transition: all;
  }
  .search__group input {
    border: none;
  }

  .server-pagination-wrapper select:focus,
  .search__group input:focus,
  .search__group select:focus {
    border: none;
    border-width: 0;
    box-shadow: none;
    outline-width: 0;
  }
  .search__group__wrapper--float {
    @apply absolute top-0 right-0 z-20 duration-75 text-sm;
  }
  .radio:checked {
    background-color: hsl(var(--p) / var(--tw-bg-opacity)) !important;
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset !important;
    border-color: hsl(var(--p) / var(--tw-border-opacity)) !important;
  }
  input[type='checkbox']:enabled:checked {
    background-color: hsl(var(--p) / var(--tw-bg-opacity)) !important;
  }
  .tab-active {
    color: #ffffff !important;
  }
  .nav-link {
    background-color: #ffffff !important;
  }
  .nav-link:hover {
    background-color: hsl(var(--bc) / var(--tw-bg-opacity)) !important;
  }

  .tracking-modal__background {
    background-image: url('./assets/map.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pdf-preview__items__item {
    @apply pl-2 w-[20%] border-2;
  }
  .pdf-preview__items__item--local {
    @apply pl-2 w-[25%] border-2;
  }

  .no_data_description_text {
    @apply text-gray-500 font-semibold text-base md:text-xl;
  }
}

.ReactModal__Overlay {
  z-index: 10;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #fe6802 !important;
}

.package-item .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em;
}
